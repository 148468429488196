<template>
  <div class="py-5 text-center">
    <h1>Picking List System</h1>
    <br />
    <b-img
      :src="logo"
      blank-color="#ccc"
      width="300"
      alt="dosetech-logo"
    ></b-img>
    <br />
    <div v-if="isLoading">
      <b-spinner variant="warning" type="grow" label="Loading..."></b-spinner>
      <b-spinner
        variant="warning"
        type="grow"
        label="Loading..."
        class="mx-2"
      ></b-spinner>
      <b-spinner variant="warning" type="grow" label="Loading..."></b-spinner>
    </div>
    <h4 class="text-danger" v-if="msg">{{ msg }}</h4>
    <h4 v-if="isLoginSuccess" class="success">Connect Success</h4>
    <div v-else>
      <b-row class="text-left justify-content-center mb-3">
        <b-col md="5">
          <div role="group">
            <label for="input-email">Mirakl Web: </label>
            <b-form-input
              id="input-email"
              v-model="formMirakl.baseUrl"
              :state="inputBaseURLState"
              aria-describedby="input-live-help input-live-feedback"
              placeholder="Enter your Mirakl web"
              trim
              name="baseUrl"
            ></b-form-input>

            <!-- This will only be shown if the preceding input has an invalid state -->
            <b-form-invalid-feedback id="input-live-feedback">
              Please enter your Mirakl web
              (ex.https://centralgroup-uat.mirakl.net)
            </b-form-invalid-feedback>
          </div>
        </b-col>
      </b-row>
      <b-row class="text-left justify-content-center mb-3">
        <b-col md="5">
          <div role="group">
            <label for="input-email">Shop Name: </label>
            <b-form-input
              id="input-email"
              v-model="formMirakl.shopName"
              :state="inputShopNameState"
              aria-describedby="input-live-help input-live-feedback"
              placeholder="Enter your Shop Name"
              trim
              name="shopName"
            ></b-form-input>

            <!-- This will only be shown if the preceding input has an invalid state -->
            <b-form-invalid-feedback id="input-live-feedback">
              Please enter your Shop Name
            </b-form-invalid-feedback>
          </div>
        </b-col>
      </b-row>
      <b-row class="text-left justify-content-center mb-3">
        <b-col md="5">
          <div role="group">
            <label for="input-password">Shop Id:</label>
            <b-form-input
              id="input-password"
              type="number"
              pattern="[0-9]*"
              v-model="formMirakl.shopId"
              :state="inputShopIdState"
              aria-describedby="input-live-help input-live-feedback"
              placeholder="Enter your Shop Id"
              trim
            ></b-form-input>

            <!-- This will only be shown if the preceding input has an invalid state -->
            <b-form-invalid-feedback id="input-live-feedback">
              Please enter your Shop Id
            </b-form-invalid-feedback>
          </div>
        </b-col>
      </b-row>
      <b-row class="text-left justify-content-center mb-3">
        <b-col md="5">
          <div role="group">
            <label for="input-password">API Key:</label>
            <b-form-input
              id="input-password"
              v-model="formMirakl.shopKey"
              :state="inputShopKeyState"
              aria-describedby="input-live-help input-live-feedback"
              placeholder="Enter your API Key"
              trim
            ></b-form-input>

            <!-- This will only be shown if the preceding input has an invalid state -->
            <b-form-invalid-feedback id="input-live-feedback">
              Please enter your API Key
            </b-form-invalid-feedback>
          </div>
        </b-col>
      </b-row>
      <b-button variant="mirakl" class="mb-2" @click="submit">Submit</b-button>
    </div>
  </div>
</template>

<script>
import logo from "@/assets/mirakl-logo.png";
import { required, minLength } from "vuelidate/lib/validators";
export default {
  validations() {
    return {
      formMirakl: {
        shopName: { required },
        shopId: { required, minLength: minLength(4) },
        shopKey: { required, minLength: minLength(8) },
        baseUrl: { required },
      },
    };
  },
  computed: {
    inputBaseURLState() {
      if (this.$v.formMirakl.baseUrl.$error)
        return !this.$v.formMirakl.baseUrl.$error;
      return this.formMirakl.baseUrl.length > 0
        ? !this.$v.formMirakl.baseUrl.$invalid
        : null;
    },
    inputShopNameState() {
      if (this.$v.formMirakl.shopName.$error)
        return !this.$v.formMirakl.shopName.$error;
      return this.formMirakl.shopName.length > 0
        ? !this.$v.formMirakl.shopName.$invalid
        : null;
    },
    inputShopIdState() {
      if (this.$v.formMirakl.shopId.$error)
        return !this.$v.formMirakl.shopId.$error;
      return this.formMirakl.shopId.length > 0
        ? !this.$v.formMirakl.shopId.$invalid
        : null;
    },
    inputShopKeyState() {
      if (this.$v.formMirakl.shopKey.$error)
        return !this.$v.formMirakl.shopKey.$error;
      return this.formMirakl.shopKey.length > 0
        ? !this.$v.formMirakl.shopKey.$invalid
        : null;
    },
  },
  data() {
    return {
      logo: logo,
      formMirakl: {
        shopName: "",
        shopId: "",
        shopKey: "",
        baseUrl: "",
      },
      isLoading: false,
      isLoginSuccess: false,
      msg: null,
    };
  },
  methods: {
    submit: async function () {
      this.msg = "";
      this.$v.formMirakl.$touch();
      if (this.$v.formMirakl.$error) return;
      if (
        this.formMirakl.baseUrl.substr(this.formMirakl.baseUrl.length - 1) ==
        "/"
      )
        this.formMirakl.baseUrl = this.formMirakl.baseUrl.slice(0, -1);
      this.isLoading = true;
      let path = `${this.$baseUrl}/mirakl/save/shop?name=${this.formMirakl.shopName}&shop_id=${this.formMirakl.shopId}&shop_key=${this.formMirakl.shopKey}&base_url=${this.formMirakl.baseUrl}`;
      await this.axios
        .get(path)
        .then(async (res) => {
          if (res.result == 1) {
            this.isLoginSuccess = true;
            this.isLoading = false;
          } else {
            this.msg = res.message;
          }
        })
        .catch((err) => {
          this.msg = err.error_message;
        })
        .then(() => {
          this.loading = false;
        });
    },

    created() {
      this.msg = "dfgdgdfg";
    },
  },
};
</script>

<style scoped>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.btn-shopee {
  color: #fff;
  background: #ee4d2d;
  padding: 8px 30px;
}
.btn-mirakl {
  color: #fff;
  background: #1d426a;
  padding: 8px 30px;
}
.success {
  color: #28a745 !important;
}
</style>